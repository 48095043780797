const toggler = document.getElementById("togglerBtn");
const sidebar = document.querySelector("#sidebar");

toggler.addEventListener("click", function () {
    sidebar.classList.toggle("open");

    if (sidebar.classList.contains("open")) {
        localStorage.setItem("sidebarState", "true");
    } else {
        localStorage.removeItem("sidebarState");
    }
});

window.addEventListener("load", () => {
    const isSideBarOpen = localStorage.getItem("sidebarState") === "true";
    if (isSideBarOpen) {
        sidebar.classList.add("open");
    }
});
